import React, { useEffect, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';

import { colors } from 'styles';
import { SEO } from 'components';

import AnydayLogo from 'assets/anyday-logo.svg';

const AGNES_BAKERY_ANYDAY_URL = 'https://anyday.com.au/agnesbakery-venue-page';

const Anyday: React.FC = () => {
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.replace(AGNES_BAKERY_ANYDAY_URL);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box>
      <SEO />

      <GlobalStyles styles={{ body: { overflow: 'hidden' } }} />

      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          width: '100%',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '30vh',
          paddingBottom: 5,
          paddingLeft: 3,
          paddingRight: 3,
        }}>
        {/* Logo */}
        <Link
          aria-label="Go to Anyday website"
          href={AGNES_BAKERY_ANYDAY_URL}
          sx={{
            display: 'inline-block',
            width: { xs: 300, sm: 400, md: 480 },
            textAlign: 'center',
          }}>
          <AnydayLogo width="100%" height="100%" />
        </Link>

        <Typography variant="h4" color={colors.WHITE} textAlign="center">
          Agnes Bakery has moved to our new website.
          <br />
          In {countdown} seconds, you will be automatically redirected to{' '}
          <Link
            href={AGNES_BAKERY_ANYDAY_URL}
            aria-label="Go to Anyday website"
            color={colors.WHITE}>
            anyday.com.au
          </Link>
        </Typography>
      </Box>

      {/* Background Img */}
      <Box
        sx={{
          position: 'fixed',
          zIndex: 1,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
        }}>
        <StaticImage
          src="../images/agnes_bakery_background.jpeg"
          alt="Agnes Bakery Background"
          layout="fullWidth"
          style={{ height: '100%' }}
        />
      </Box>
    </Box>
  );
};

export default Anyday;
